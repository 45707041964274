import React, {useEffect, useState} from "react";
import ExchangeParameters from "../data/ExchangeParameters";
import Arrows from '../../../images/icons/icon-double-arrow.svg';
import { NumericFormat } from 'react-number-format';

const App = () => {
    const parameters = ExchangeParameters();
    const compra = parameters.exchange_rate_buy;
    const venta = parameters.exchange_rate_sale;

    let [selected, setSelected] = useState('usd');
    let [value, setValue] = useState(0);
    let [result, setResult] = useState(0);

    useEffect(() => {
        if (selected === 'usd') {
            // dolar a cordoba
            setResult(value * parseFloat(compra));
        } else {
            // cordoba a dolar
            setResult(value / parseFloat(venta));
        }
    }, [value]);

    const hadleClick = () => {
        if (selected === 'usd') {
            document.getElementById('select').value = 'cor';
            setSelected('cor');
            setResult(value / parseFloat(venta));
        } else {
            setResult(value * parseFloat(compra));
            setSelected('usd');
            document.getElementById('select').value = 'usd';
        }
    }

    return (
        <>
            <div className="exchange_rate_data--currencies">
                <div className="exchange_rate_data--country">
                    <p className="mb-4 title">Moneda</p>
                    <p>USD</p>
                </div>
                <div className="exchange_rate_data--buy">
                    <p className="mb-4 title">Compra</p>
                    <p>C$ {parameters.exchange_rate_buy}</p>
                </div>
                <div className="exchange_rate_data--sale">
                    <p className="mb-4 title">Venta</p>
                    <p>C$ {parameters.exchange_rate_sale}</p>
                </div>
            </div>
            <div className="exchange_rate_data--inputs">
                <p className="exchange_rate_data--inputs--desc">Digite el monto que desea convertir en el siguiente espacio:</p>
                <div className="exchange_rate_data--inputs--wrap">
                    <label className="visually-hidden" htmlFor="montoInput">Monto</label>
                    <NumericFormat id="montoInput" className="inputNumber" onValueChange={(e) => setValue(e.floatValue) } allowLeadingZeros thousandSeparator="," decimalSeparator="." min={0} />
                    <label className="visually-hidden" htmlFor="select">Seleccionar moneda</label>
                    <select id="select" onChange={hadleClick}>
                        <option value="usd">USD</option>
                        <option value="cor">COR</option>
                    </select>
                    <button className="btn-exchange" onClick={hadleClick}>
                        <span className="visually-hidden">Intercambiar conversión</span>
                        <img src={Arrows} alt="" width="20" height="20"></img>
                    </button>
                </div>
                <div className="exchange_rate_data--inputs--result">
                    {/* Si es cordoba a dolar */}
                    <p className="title">Cantidad de transacción</p>
                    {/* resultado */}
                    <p className="font-bold">{selected === 'usd' ? (<>C$ </>) : (<>$ </>)}{Number(result.toString().match(/^\d+(?:\.\d{0,2})?/)).toLocaleString('en-US')}</p>
                </div>
            </div>
        </>
    );
};

export default App;
