import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App'

const renderer = ReactDOM.createRoot(document.getElementById('app_exchange'));
renderer.render(
    <>
        <App />
    </>
)

// ReactDOM.render(<App />, document.getElementById('app_exchange'));