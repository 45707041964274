import { useEffect, useState } from "react";
import axios from 'axios';

const ExchangeParameters  = () => {
    const [data, setData] = useState(0);
    // useEffect(() => {
    //     fetch('/api/exchange-rates/')
    //         .then((response) => response.json())
    //         .then((data) => {
    //             setData(data);
    //         })
    //         .catch((err) => {
    //             console.log(err.message);
    //         });
    // })
    useEffect(() => {
        (async () => {
            const response = await axios('/api/exchange-rates/');
            setData(response.data);
        })();
    }, [])

    return data;
}

export default ExchangeParameters;